<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <p>
          Tahun Ajaran Berlangsung :
          <i>
            <strong>{{ activeData }}</strong>
          </i>
        </p>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section
          class="column q-pt-none justify-between"
          style="min-width:300px"
        >
          <div class="q-gutter-md">
            <q-select
              outlined
              v-model="selJenjang"
              :options="$LIST_JENJANG"
              label="Jenjang"
              @input="getListTahunAjar"
            />
          </div>
          <q-btn
            filled
            color="primary"
            label="TAMBAH TAHUN AJARAN"
            v-can="['ADMIN', 'AKADEMIK']"
            :disable="selJenjang == null"
            :to="`/pengaturantambahtahunajaran/-1/${selJenjang}`"
          ></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          wrap-cells
          class="stickyTable bg-indigo-1 full-width"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th>No</th>
              <th>Tahun Ajaran</th>
              <th>Semester</th>
              <th>Tanggal Mulai</th>
              <th>Tanggal Selesai</th>
              <th>Jumlah Minggu</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in listTahunAjar" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ val.nama }}</td>
              <td>{{ val.semester }}</td>
              <td>{{ val.tanggal_mulai | moment("DD-MMM-YYYY") }}</td>
              <td>{{ val.tanggal_selesai | moment("DD-MMM-YYYY") }}</td>
              <td>{{ val.jumlah_minggu }}</td>
              <td>{{ val.status }}</td>
              <td>
                <q-btn
                  flat
                  icon="build"
                  :to="`/pengaturantambahtahunajaran/${val.id}/${selJenjang}`"
                ></q-btn>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    activeData() {
      if (this.current.tahun == "-") return "-";
      return `${this.current.tahun} (${this.current.semester})`;
    },
  },
  data() {
    return {
      selJenjang: this.$LIST_JENJANG[0],
      is_active: true,
      confirm: false,
      current: {
        tahun: "-",
        semester: "",
      },
      listTahunAjar: {},
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    this.selJenjang = this.$route.params.jenjang;
    await this.getListTahunAjar();
    this.$q.loading.hide();
  },
  methods: {
    async getListTahunAjar() {
      if (this.$route.params.jenjang != this.selJenjang) {
        this.$router.replace({
          path: `/pengaturanlisttahunajaran/${this.selJenjang}`,
        });
      }
      let resp = await this.$http.get(
        `/pengaturan/tahun_ajaran/getlist/${this.selJenjang}`
      );
      this.current = {
        tahun: "-",
        semester: "",
      };
      for (let item of resp.data) {
        item.status = "TIDAK AKTIF";

        if (item.is_active == 1) {
          item.status = "AKTIF";
          this.current = {
            tahun: item.nama,
            semester: item.semester,
          };
        }
      }
      this.listTahunAjar = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
